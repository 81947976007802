import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'

import authReducer from './authentication/reducers'
import CommonReducer from './common/reducers'

const reducer = combineReducers({
  common: CommonReducer,
  auth: authReducer
})
const store = configureStore({
  reducer
})
export default store
