import { DataService } from './index'

class Notify {
  updateReaded = NotifyID => {
    const url = `/api/Notify/UpdateReaded?NotifyID=${NotifyID}`
    return DataService.post(url)
  }

  updateAllReaded = () => {
    const url = `/api/Notify/UpdateAllReaded`
    return DataService.post(url)
  }

  deleteAll = () => {
    const url = `/api/Notify/DeleteAll`
    return DataService.post(url)
  }

  getList = (PageSize, CurrentPage) => {
    const url = `/api/Notify/GetList?PageSize=${PageSize}&CurrentPage=${CurrentPage}`
    return DataService.get(url)
  }

  totalUnread = () => {
    const url = `/api/Notify/TotalUnread`
    return DataService.get(url)
  }
}

const NotifyService = new Notify()

export default NotifyService
