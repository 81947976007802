import React, { lazy } from 'react'
import { Switch, Route, useRouteMatch } from 'react-router-dom'

const AddFile = lazy(() => import('src/pages/DataManagement/components/AddFile'))
const AddPaperDocument = lazy(() => import('src/pages/DataManagement/components/AddPaperDocument'))
const AddFilmDocument = lazy(() => import('src/pages/DataManagement/components/AddFilmDocument'))

// Paper File
const AudiovisualFile = lazy(() => import('src/pages/DataManagement/AudiovisualFile'))
const DetailAudiovisualFile = lazy(() =>
  import('src/pages/DataManagement/AudiovisualFile/components/DetailAudiovisualFile')
)

const AudioFileRoutes = () => {
  const { path } = useRouteMatch()
  return (
    <Switch>
      {/* GDC */}
      <Route exact path={`${path}`} component={AudiovisualFile} />

      {/* Create */}
      <Route exact path={`${path}/:FileType/create`} component={AddFile} />
      <Route exact path={`${path}/:FileObjectGuid`} component={AddFile} />

      {/* Detail */}
      <Route exact path={`${path}/:FileObjectGuid/:tabActive`} component={DetailAudiovisualFile} />

      {/* Create Document In Paper-File */}
      <Route exact path={`${path}/:FileObjectGuid/paper/create`} component={AddPaperDocument} />
      <Route exact path={`${path}/:FileObjectGuid/paper/:ObjectGuid`} component={AddPaperDocument} />

      <Route exact path={`${path}/:FileObjectGuid/film/create`} component={AddFilmDocument} />
      <Route exact path={`${path}/:FileObjectGuid/film/:ObjectGuid`} component={AddFilmDocument} />
    </Switch>
  )
}

export default AudioFileRoutes
