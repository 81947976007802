import React from 'react'
import ReactDOM from 'react-dom'
import { ThemeProvider } from 'styled-components'
import { Provider } from 'react-redux'
import { ChakraProvider } from '@chakra-ui/react'
import { ConfigProvider } from 'antd'
import vnVN from 'antd/lib/locale/vi_VN'
import 'moment/locale/vi'
import moment from 'moment'

import App from './App'
import './assets/scss/index.scss'

import * as serviceWorker from './serviceWorker'
import { theme } from './theme'
import store from './store'

moment.locale('vi')

ReactDOM.render(
  <>
    <Provider store={store}>
      <ChakraProvider resetCSS>
        <ThemeProvider theme={{ ...theme }}>
          <ConfigProvider locale={vnVN}>
            <App />
          </ConfigProvider>
        </ThemeProvider>
      </ChakraProvider>
    </Provider>
  </>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
