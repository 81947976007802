import actions from './actions'

const { LOGIN_BEGIN, LOGIN_SUCCESS, LOGIN_ERR, LOGOUT_BEGIN, LOGOUT_SUCCESS, LOGOUT_ERR } = actions

const initState = {
  user: JSON.parse(localStorage.getItem('user')),
  loading: false,
  error: null
}

/**
 *
 * @todo impure state mutation/explaination
 */
const AuthReducer = (state = initState, action) => {
  const { type, data, err } = action
  switch (type) {
    case LOGIN_BEGIN:
      return {
        ...state,
        loading: true
      }
    case LOGIN_SUCCESS:
      return {
        ...state,
        user: data,
        loading: false
      }
    case LOGIN_ERR:
      return {
        ...state,
        error: err,
        loading: false
      }
    case LOGOUT_BEGIN:
      return {
        ...state,
        loading: true
      }
    case LOGOUT_SUCCESS:
      return {
        ...state,
        user: data,
        loading: false
      }
    case LOGOUT_ERR:
      return {
        ...state,
        error: err,
        loading: false
      }
    default:
      return state
  }
}
export default AuthReducer
