import React, { Suspense, useEffect } from 'react'
import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import styles from 'src/assets/scss/index.scss'

// API Service
import CommonService from 'src/api/CommonService'
import UserService from 'src/api/UserService'

// Components
import MainLayout from 'src/layouts/MainLayout'
import LoadingPage from 'src/components/LoadingPage'
import Wellcome from 'src/pages/Wellcome'
import { ModalHistory } from 'src/components/Modals/component/ModalHistory'
import { ModalFormDataMining } from 'src/components/Modals/component/ModalFormDataMining'

// Store Redux
import actions from 'src/store/common/actions'

// Route
import Dashboard from './dashboard'
import Search from './search'
import PaperFile from './paper-file'
import LookFile from './look-file'
import AudioFile from './audio-file'
import DeliveryFileRoutes from './delivery-file'

import TicketManagement from './ticket'
import SettingManagementRoutes from './settings'
import ReportRoutes from './report'

const Admin = () => {
  const { path } = useRouteMatch()
  const history = useHistory()
  const dispatch = useDispatch()
  const { isLoadingApp } = useSelector(state => state.common)

  useEffect(() => {
    initCommonData()
  }, [])

  const initCommonData = () => {
    dispatch(actions.setIsLoadingOn())
    Promise.all([
      CommonService.getListCommonData(
        'DOCUMENT_TYPE,FILE_GROUP,LANGUAGE,TICKET_STATUS,SYNC_STATUS,TICKET_MINING_TYPE,QUOC_HOI_KHOA,KY_HOP_THU,PHIEN_HOP_THU,ORGAN_NAME,AGENCY_CREATE,CONFIDENCE_LEVEL,SECURITY_LEVEL,UNIT_PERSONAL_DELIVERY'
      ),
      UserService.GetListTab()
    ])
      .then(res => {
        if (res[0]?.Object) {
          dispatch(actions.setDocumentType(res[0].Object.find(data => data.key.trim() === 'DOCUMENT_TYPE').data))
          dispatch(actions.setFileGroup(res[0].Object.find(data => data.key.trim() === 'FILE_GROUP').data))
          dispatch(actions.setLanguage(res[0].Object.find(data => data.key.trim() === 'LANGUAGE').data))
          dispatch(actions.setTicketStatus(res[0].Object.find(data => data.key.trim() === 'TICKET_STATUS').data))
          dispatch(actions.setSyncStatus(res[0].Object.find(data => data.key.trim() === 'SYNC_STATUS').data))
          dispatch(
            actions.setTicketMiningType(res[0].Object.find(data => data.key.trim() === 'TICKET_MINING_TYPE').data)
          )
          dispatch(actions.setNationalAssembly(res[0].Object.find(data => data.key.trim() === 'QUOC_HOI_KHOA').data))
          dispatch(actions.setCongressMeeting(res[0].Object.find(data => data.key.trim() === 'KY_HOP_THU').data))
          dispatch(actions.setMeeting(res[0].Object.find(data => data.key.trim() === 'PHIEN_HOP_THU').data))
          dispatch(actions.setOrganName(res[0].Object.find(data => data.key.trim() === 'ORGAN_NAME').data))
          dispatch(actions.setAgencyCreate(res[0].Object.find(data => data.key.trim() === 'AGENCY_CREATE').data))
          dispatch(actions.setConfidenceLevel(res[0].Object.find(data => data.key.trim() === 'CONFIDENCE_LEVEL').data))
          dispatch(actions.setSecurityLevel(res[0].Object.find(data => data.key.trim() === 'SECURITY_LEVEL').data))
          dispatch(
            actions.setUnitPersonalDelivery(
              res[0].Object.find(data => data.key.trim() === 'UNIT_PERSONAL_DELIVERY').data
            )
          )
        }

        if (!res[1]?.isError && res[1]?.Object) {
          dispatch(actions.setListTab(res[1].Object.ListTab))
          const currentTab = res[1].Object.RoleGroup.RoleGroupType
          if (currentTab === 5) history.push('/search')
          if (currentTab === 1) history.push('/settings')
          if ([2, 3, 4].includes(currentTab)) history.push('/')
          if (res[1]?.Object?.RoleGroup) {
            dispatch(actions.setRoleTypeId(res[1]?.Object?.RoleGroup?.RoleGroupType))
          }
        }
      })

      .finally(() => dispatch(actions.setIsLoadingOff()))
  }

  return isLoadingApp ? (
    <Wellcome />
  ) : (
    <MainLayout className={styles.defaultTheme}>
      <Switch>
        <Suspense fallback={<LoadingPage />}>
          <Route exact path={path} component={Dashboard} />
          <Route path="/search" component={Search} />
          <Route path="/paper-file" component={PaperFile} />
          <Route path="/look-file" component={LookFile} />
          <Route path="/audio-file" component={AudioFile} />
          <Route path="/delivery-file" component={DeliveryFileRoutes} />
          <Route path="/ticket" component={TicketManagement} />
          <Route path="/settings" component={SettingManagementRoutes} />
          <Route path="/report" component={ReportRoutes} />
        </Suspense>
      </Switch>
      <ModalHistory type="primary" onCancel={() => dispatch(actions.setCloseModalHistory())} />
      <ModalFormDataMining type="primary" onCancel={() => dispatch(actions.setCloseModalDataMining())} />
    </MainLayout>
  )
}

export default Admin
