const actions = {
  SET_DOCUMENT_TYPE: 'SET_DOCUMENT_TYPE',
  SET_FILE_GROUP: 'SET_FILE_GROUP',
  SET_LANGUAGE: 'SET_LANGUAGE',
  SET_TICKET_STATUS: 'SET_TICKET_STATUS',
  SET_SYNC_STATUS: 'SET_SYNC_STATUS',
  SET_TICKET_MINING_TYPE: 'SET_TICKET_MINING_TYPE',
  SET_NATIONAL_ASSEMBLY: 'SET_NATIONAL_ASSEMBLY',
  SET_CONGRESS_MEETING: 'SET_CONGRESS_MEETING',
  SET_MEETING: 'SET_MEETING',
  SET_ORGAN_NAME: 'SET_ORGAN_NAME',
  SET_AGENCY_CREATE: 'SET_AGENCY_CREATE',
  SET_CONFIDENCE_LEVEL: 'SET_CONFIDENCE_LEVEL',
  SET_SECURITY_LEVEL: 'SET_SECURITY_LEVEL',
  TURN_ON_LOADING: 'TURN_ON_LOADING',
  TURN_OFF_LOADING: 'TURN_OFF_LOADING',
  SET_OPEN_MODAL_HISTORY: 'SET_OPEN_MODAL_HISTORY',
  SET_CLOSE_MODAL_HISTORY: 'SET_CLOSE_MODAL_HISTORY',
  SET_OBJECTGUID_HISTORY: 'SET_OBJECTGUID_HISTORY',
  SET_LIST_TAB: 'SET_LIST_TAB',
  SET_OPEN_MODAL_DATA_MINING: 'SET_OPEN_MODAL_DATA_MINING',
  SET_CLOSE_MODAL_DATA_MINING: 'SET_CLOSE_MODAL_DATA_MINING',
  SET_TICKET_OBJECTGUID: 'SET_TICKET_OBJECTGUID',
  SET_LIST_REFERENCES_FROM_SEARCH: 'SET_LIST_REFERENCES_FROM_SEARCH',
  SET_GET_LIST_TICKET: 'SET_GET_LIST_TICKET',
  SET_ROLE_TYPE_ID: 'SET_ROLE_TYPE_ID',
  SET_IS_LOAD_LIST_SEARCH: 'SET_IS_LOAD_LIST_SEARCH',
  SET_SEARCH_DATE: 'SET_SEARCH_DATE',
  SET_UNIT_PERSONAL_DELIVERY: 'SET_UNIT_PERSONAL_DELIVERY',

  setListTab: data => {
    return {
      type: actions.SET_LIST_TAB,
      data
    }
  },

  setDocumentType: data => {
    return {
      type: actions.SET_DOCUMENT_TYPE,
      data
    }
  },

  setLanguage: data => {
    return {
      type: actions.SET_LANGUAGE,
      data
    }
  },

  setFileGroup: data => {
    return {
      type: actions.SET_FILE_GROUP,
      data
    }
  },

  setTicketStatus: data => {
    return {
      type: actions.SET_TICKET_STATUS,
      data
    }
  },

  setSyncStatus: data => {
    return {
      type: actions.SET_SYNC_STATUS,
      data
    }
  },

  setTicketMiningType: data => {
    return {
      type: actions.SET_TICKET_MINING_TYPE,
      data
    }
  },

  setIsLoadingOn: () => {
    return {
      type: actions.TURN_ON_LOADING
    }
  },

  setIsLoadingOff: () => {
    return {
      type: actions.TURN_OFF_LOADING
    }
  },

  setNationalAssembly: data => {
    return {
      type: actions.SET_NATIONAL_ASSEMBLY,
      data
    }
  },

  setCongressMeeting: data => {
    return {
      type: actions.SET_CONGRESS_MEETING,
      data
    }
  },

  setMeeting: data => {
    return {
      type: actions.SET_MEETING,
      data
    }
  },

  setOrganName: data => {
    return {
      type: actions.SET_ORGAN_NAME,
      data
    }
  },

  setAgencyCreate: data => {
    return {
      type: actions.SET_AGENCY_CREATE,
      data
    }
  },

  setConfidenceLevel: data => {
    return {
      type: actions.SET_CONFIDENCE_LEVEL,
      data
    }
  },

  setSecurityLevel: data => {
    return {
      type: actions.SET_SECURITY_LEVEL,
      data
    }
  },

  setUnitPersonalDelivery: data => {
    console.log(data)
    return {
      type: actions.SET_UNIT_PERSONAL_DELIVERY,
      data
    }
  },

  setObjectGuidHistory: data => {
    return {
      type: actions.SET_OBJECTGUID_HISTORY,
      data
    }
  },

  setOpenModalHistory: () => {
    return {
      type: actions.SET_OPEN_MODAL_HISTORY
    }
  },

  setCloseModalHistory: () => {
    return {
      type: actions.SET_CLOSE_MODAL_HISTORY
    }
  },

  setOpenModalDataMining: () => {
    return {
      type: actions.SET_OPEN_MODAL_DATA_MINING
    }
  },

  setCloseModalDataMining: () => {
    return {
      type: actions.SET_CLOSE_MODAL_DATA_MINING
    }
  },

  setTicketObjectGuid: data => {
    return {
      type: actions.SET_TICKET_OBJECTGUID,
      data
    }
  },

  setListReferencesFromSearch: data => {
    return {
      type: actions.SET_LIST_REFERENCES_FROM_SEARCH,
      data
    }
  },

  setGetListTicket: data => {
    return {
      type: actions.SET_GET_LIST_TICKET,
      data
    }
  },

  setRoleTypeId: data => {
    return {
      type: actions.SET_ROLE_TYPE_ID,
      data
    }
  },

  setIsLoadListSearch: data => {
    return {
      type: actions.SET_IS_LOAD_LIST_SEARCH,
      data
    }
  },

  setSearchByDate: data => {
    return {
      type: actions.SET_SEARCH_DATE,
      data
    }
  }
}

export default actions
