import React, { lazy } from 'react'
import { Switch, Route, useRouteMatch } from 'react-router-dom'

const AddFile = lazy(() => import('src/pages/DataManagement/components/AddFile'))
const AddGallery = lazy(() => import('src/pages/DataManagement/components/AddGallery'))
const AddPhotoDocument = lazy(() => import('src/pages/DataManagement/components/AddPhotoDocument'))
const AddFilmDocument = lazy(() => import('src/pages/DataManagement/components/AddFilmDocument'))

// Paper File
const LookFile = lazy(() => import('src/pages/DataManagement/LookFile'))
const DetailLookFile = lazy(() => import('src/pages/DataManagement/LookFile/components/DetailLookFile'))

const LookFileRoutes = () => {
  const { path } = useRouteMatch()
  return (
    <Switch>
      {/* GDC */}
      <Route exact path={`${path}`} component={LookFile} />

      {/* Create */}
      <Route exact path={`${path}/:FileType/create`} component={AddFile} />
      <Route exact path={`${path}/:FileObjectGuid`} component={AddFile} />

      {/* Detail */}
      <Route exact path={`${path}/:FileObjectGuid/:tabActive`} component={DetailLookFile} />

      {/* Create Document In Paper-File */}
      <Route exact path={`${path}/:FileObjectGuid/gallery/create`} component={AddGallery} />
      <Route exact path={`${path}/:FileObjectGuid/gallery/:ObjectGuid`} component={AddGallery} />

      <Route exact path={`${path}/:FileObjectGuid/film/create`} component={AddFilmDocument} />
      <Route
        exact
        path={`${path}/:FileObjectGuid/:tabActive/:GalleryObjectGuid/photo/create`}
        component={AddPhotoDocument}
      />
      <Route
        exact
        path={`${path}/:FileObjectGuid/:tabActive/:GalleryObjectGuid/photo/:ObjectGuid`}
        component={AddPhotoDocument}
      />
      <Route exact path={`${path}/:FileObjectGuid/:tabActive/:GalleryObjectGuid/photo`} component={DetailLookFile} />
    </Switch>
  )
}

export default LookFileRoutes
