import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { BrowserRouter as Router, Redirect, Route } from 'react-router-dom'
import { createBrowserHistory } from 'history'

import './App.css'
import AuthenticatedGuard from 'src/guards/AuthenticatedGuard'
import Admin from './routes/admin'
import Auth from './routes/auth'

function App() {
  const { isLoggedIn } = useSelector(state => {
    return {
      isLoggedIn: !!state.auth.user
    }
  })

  const history = createBrowserHistory()

  const [path, setPath] = useState(window.location.pathname)

  useEffect(() => {
    let unmounted = false
    if (!unmounted) {
      setPath(window.location.pathname)
    }
    // eslint-disable-next-line no-return-assign
    return () => (unmounted = true)
  }, [setPath])

  return (
    <Router basename={process.env.PUBLIC_URL} history={history}>
      {!isLoggedIn ? <Route path="/" component={Auth} /> : <AuthenticatedGuard path="" component={Admin} />}
      {isLoggedIn && (path === process.env.PUBLIC_URL || path === `${process.env.PUBLIC_URL}/`) && <Redirect to="/" />}
    </Router>
  )
}

export default App
