import React, { lazy } from 'react'
import { Switch, Route } from 'react-router-dom'
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min'

const SearchPage = lazy(() => import('src/pages/Search'))

const SearchRoutes = () => {
  const { path } = useRouteMatch()
  return (
    <Switch>
      <Route exact path={path} component={SearchPage} />
    </Switch>
  )
}

export default SearchRoutes
