import React, { lazy } from 'react'
import { Switch, Route } from 'react-router-dom'
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min'

const DeliveryFilePage = lazy(() => import('src/pages/DataManagement/DeliveryFile'))

const DeliveryFileRoutes = () => {
  const { path } = useRouteMatch()
  return (
    <Switch>
      <Route exact path={path} component={DeliveryFilePage} />
    </Switch>
  )
}

export default DeliveryFileRoutes
