import queryString from 'query-string'
import { DataService } from './index'

class AccountDept {
  getListUserByDirectory = param => {
    const query = queryString.stringify(param)
    const url = `api/AccountDept/GetListUserByDirectory?${query}`
    return DataService.get(url)
  }
}

const AccountDeptService = new AccountDept()

export default AccountDeptService
