import React, { lazy, Suspense } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import LoadingPage from 'src/components/LoadingPage'

const Login = lazy(() => import('src/pages/Login'))
const NotFound = () => {
  return <Redirect to="/" />
}

const AuthRoutes = () => {
  return (
    <Switch>
      <Suspense fallback={<LoadingPage />}>
        <Route exact path="/" component={Login} />
        <Route exact path="*" component={NotFound} />
      </Suspense>
    </Switch>
  )
}

export default AuthRoutes
