import { DataService } from './index'

class SearchDocument {
  getListAdvancedSearch = body => {
    const url = `/api/SearchDocument/GetListAdvancedSearch`
    return DataService.post(url, body)
  }

  getListAdvancedTicketSearch = body => {
    const url = `/api/SearchDocument/GetListAdvancedTicketSearch`
    return DataService.post(url, body)
  }
}

const SearchDocumentService = new SearchDocument()

export default SearchDocumentService
