import React, { lazy } from 'react'
import { Switch, Route } from 'react-router-dom'
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min'

const UserManagement = lazy(() => import('src/pages/Setting/UserManagement'))
const RoleGroupManagement = lazy(() => import('src/pages/Setting/RoleGroupManagement'))
const StorageUnitManagement = lazy(() => import('src/pages/Setting/StorageUnitManagement'))

const SettingManagementRoutes = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route exact path={path} component={UserManagement} />
      <Route exact path={`${path}/roles`} component={RoleGroupManagement} />
      <Route exact path={`${path}/storage-unit`} component={StorageUnitManagement} />
    </Switch>
  )
}

export default SettingManagementRoutes
