import React, { lazy } from 'react'
import { Switch, Route } from 'react-router-dom'
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min'

const ListTicket = lazy(() => import('src/pages/Ticket/ListTicket'))

const CategoryExplore = lazy(() => import('src/pages/Ticket/CategoryExplore'))

const TicketRoutes = () => {
  const { path } = useRouteMatch()
  return (
    <Switch>
      <Route exact path={path} component={ListTicket} />
      <Route exact path={`${path}/list`} component={ListTicket} />
      <Route exact path={`${path}/category-explore`} component={CategoryExplore} />
    </Switch>
  )
}

export default TicketRoutes
