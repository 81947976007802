import { DataService } from './index'

class Common {
  getListCommonData = param => {
    const url = `/api/Common/GetListCommonData?keys=${param}`
    return DataService.get(url)
  }

  getInventoryByType = typeId => {
    const url = `/api/Common/InventoryByType?TypeId=${typeId}`
    return DataService.get(url)
  }

  getListChildInventory = id => {
    const url = `/api/Common/ListChildInventory/${id}`
    return DataService.get(url)
  }

  history = objectGuid => {
    const body = {
      ObjectGuid: objectGuid
    }
    const url = `/api/Common/History`
    return DataService.post(url, body)
  }
}

const CommonService = new Common()

export default CommonService
