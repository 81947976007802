import React, { lazy } from 'react'
import { Switch, Route } from 'react-router-dom'
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min'

const GenaralReport = lazy(() => import('src/pages/Report/GenaralReport'))
const FileCatalouge = lazy(() => import('src/pages/Report/components/FileCatalouge'))
const DocumentCatalouge = lazy(() => import('src/pages/Report/components/DocumentCatalouge'))
const StorageFileKeywordSearch = lazy(() => import('src/pages/Report/components/StorageFileKeywordSearch'))
const DocumentSecretReport = lazy(() => import('src/pages/Report/DocumentSecretReport'))
const ReportsExploitation = lazy(() => import('src/pages/Ticket/ReportsExploitation'))
const ReportsExploitationDetail = lazy(() => import('src/pages/Ticket/ReportsExploitationDetail'))

const SettingManagementRoutes = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route exact path={path} component={GenaralReport} />
      <Route exact path={`${path}/file-catalogue`} component={FileCatalouge} />
      <Route exact path={`${path}/document-catalogue`} component={DocumentCatalouge} />
      <Route exact path={`${path}/document-keyword`} component={StorageFileKeywordSearch} />
      <Route exact path={`${path}/document-secret`} component={DocumentSecretReport} />
      <Route exact path={`${path}/report-explore`} component={ReportsExploitation} />
      <Route exact path={`${path}/report-explore`} component={ReportsExploitation} />
      <Route exact path={`${path}/report-explore/:id`} component={ReportsExploitationDetail} />
    </Switch>
  )
}

export default SettingManagementRoutes
