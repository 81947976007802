import { DataService } from './index'

class Ticket {
  getListAdvancedSearch = body => {
    const url = `/api/Ticket/GetListAdvancedSearch`
    return DataService.post(url, body)
  }

  getOne = ObjectGuid => {
    const url = `/api/Ticket/GetOne?ObjectGuid=${ObjectGuid}`
    return DataService.get(url)
  }

  insertUpdateTicket = body => {
    const url = `/api/Ticket/InsertUpdate`
    return DataService.post(url, body)
  }

  reject = body => {
    const url = `/api/Ticket/Reject`
    return DataService.post(url, body)
  }

  delete = body => {
    const url = `/api/Ticket/Delete`
    return DataService.post(url, body)
  }

  export = body => {
    const url = `/api/Ticket/Export`
    return DataService.post(url, body)
  }

  approve = body => {
    const url = `/api/Ticket/Approve`
    return DataService.post(url, body)
  }

  exportPDF = body => {
    const url = `/api/Ticket/ExportPDF`
    return DataService.post(url, body)
  }
}

const TicketService = new Ticket()

export default TicketService
