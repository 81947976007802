import React from 'react'
// import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Spinner } from '@chakra-ui/react'

import logo from 'src/assets/images/logo-quoc-hoi.png'

export const WellcomeWrapper = styled.div`
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--color-primary);
`

function Wellcome() {
  return (
    <WellcomeWrapper>
      <div className="mb-3">
        <img src={logo} alt="logo" width="80px" />
      </div>
      <Spinner color="var(--color-white)" thickness="3px" />
    </WellcomeWrapper>
  )
}

Wellcome.propTypes = {}

export default Wellcome
