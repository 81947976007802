import actions from './actions'

const {
  SET_DOCUMENT_TYPE,
  SET_FILE_GROUP,
  SET_LANGUAGE,
  SET_TICKET_STATUS,
  SET_SYNC_STATUS,
  SET_TICKET_MINING_TYPE,
  TURN_ON_LOADING,
  TURN_OFF_LOADING,
  SET_NATIONAL_ASSEMBLY,
  SET_CONGRESS_MEETING,
  SET_MEETING,
  SET_ORGAN_NAME,
  SET_AGENCY_CREATE,
  SET_CONFIDENCE_LEVEL,
  SET_SECURITY_LEVEL,
  SET_OPEN_MODAL_HISTORY,
  SET_CLOSE_MODAL_HISTORY,
  SET_OBJECTGUID_HISTORY,
  SET_OPEN_MODAL_DATA_MINING,
  SET_CLOSE_MODAL_DATA_MINING,
  SET_TICKET_OBJECTGUID,
  SET_LIST_TAB,
  SET_LIST_REFERENCES_FROM_SEARCH,
  SET_GET_LIST_TICKET,
  SET_ROLE_TYPE_ID,
  SET_IS_LOAD_LIST_SEARCH,
  SET_SEARCH_DATE,
  SET_UNIT_PERSONAL_DELIVERY
} = actions

const initialState = {
  documentTypes: [],
  fileGroup: [],
  languages: [],
  ticketStatus: [],
  syncStatus: [],
  ticketMiningType: [],
  nationalAssembly: [],
  congressMeeting: [],
  meeting: [],
  organName: [],
  agencyCreate: [],
  confidenceLevel: [],
  securityLevel: [],
  unitPersinalDelivery: [],
  isOpenModalHistory: false,
  isOpenModalDataMining: false,
  objectGuidHistory: null,
  ticketObjectGuid: null,
  isLoadingApp: false,
  listTab: [],
  listReferences: [],
  isGetListTicket: false,
  roleTypeID: null,
  isLoadListSearch: false,
  searchByDate: {
    DateType: 2,
    FromDateInput: '',
    ToDateInput: '',
    PageSize: 50,
    CurrentPage: 1
  }
}

const CommonReducer = (state = initialState, action) => {
  const { type, data } = action
  switch (type) {
    case SET_LIST_TAB:
      return {
        ...state,
        listTab: data || []
      }

    case SET_DOCUMENT_TYPE:
      return {
        ...state,
        documentTypes: data || []
      }

    case SET_FILE_GROUP:
      return {
        ...state,
        fileGroup: data || []
      }

    case SET_LANGUAGE:
      return {
        ...state,
        languages: data || []
      }

    case SET_TICKET_STATUS:
      return {
        ...state,
        ticketStatus: data || []
      }

    case SET_SYNC_STATUS:
      return {
        ...state,
        syncStatus: data || []
      }

    case SET_TICKET_MINING_TYPE:
      return {
        ...state,
        ticketMiningType: data || []
      }

    case TURN_ON_LOADING:
      return {
        ...state,
        isLoadingApp: true
      }

    case TURN_OFF_LOADING:
      return {
        ...state,
        isLoadingApp: false
      }

    case SET_NATIONAL_ASSEMBLY:
      return {
        ...state,
        nationalAssembly: data || []
      }

    case SET_CONGRESS_MEETING:
      return {
        ...state,
        congressMeeting: data || []
      }

    case SET_MEETING:
      return {
        ...state,
        meeting: data || []
      }

    case SET_ORGAN_NAME:
      return {
        ...state,
        organName: data || []
      }

    case SET_AGENCY_CREATE:
      return {
        ...state,
        agencyCreate: data || []
      }

    case SET_CONFIDENCE_LEVEL:
      return {
        ...state,
        confidenceLevel: data || []
      }

    case SET_SECURITY_LEVEL:
      return {
        ...state,
        securityLevel: data || []
      }

    case SET_UNIT_PERSONAL_DELIVERY:
      return {
        ...state,
        unitPersinalDelivery: data || []
      }

    case SET_OPEN_MODAL_HISTORY:
      return {
        ...state,
        isOpenModalHistory: true
      }

    case SET_CLOSE_MODAL_HISTORY:
      return {
        ...state,
        isOpenModalHistory: false,
        objectGuidHistory: null
      }

    case SET_OBJECTGUID_HISTORY:
      return {
        ...state,
        objectGuidHistory: data
      }

    case SET_TICKET_OBJECTGUID:
      return {
        ...state,
        ticketObjectGuid: data
      }
    case SET_LIST_REFERENCES_FROM_SEARCH:
      return {
        ...state,
        listReferencesFromSearch: data
      }

    case SET_OPEN_MODAL_DATA_MINING:
      return {
        ...state,
        isOpenModalDataMining: true
      }

    case SET_CLOSE_MODAL_DATA_MINING:
      return {
        ...state,
        isOpenModalDataMining: false
      }

    case SET_GET_LIST_TICKET:
      return {
        ...state,
        isGetListTicket: data
      }

    case SET_ROLE_TYPE_ID:
      return {
        ...state,
        roleTypeID: data
      }

    case SET_IS_LOAD_LIST_SEARCH:
      return {
        ...state,
        isLoadListSearch: data
      }
    case SET_SEARCH_DATE:
      return {
        ...state,
        searchByDate: data
      }
    default:
      return state
  }
}

export default CommonReducer
